<template>
  <ion-page id="stampCardDetail">
    <ion-modal
      @didDismiss="preGrantModel = false"
      :is-open="preGrantModel"
      css-class="tcc-modal"
      :animated="false"
    >
      <div class="model-box">
        <div class="ion-page">
          <div class="modal-content friend-modal">
            <p class="font-18 font-b main-color">
              Thank you for joining Ritchies. To get you started, we are giving you FREE
              stamps!
            </p>
            <p class="font-16 gery-color" style="margin-top: 30px">
              Only stamps earned from purchase are transferrable!
            </p>
            <div class="btn-box" style="display: flex; margin-top: 38px">
              <button class="btn" @click="preGrantModel = false">Later</button>
              <button class="btn" @click="grantEvent">Accept</button>
            </div>
          </div>
        </div>
      </div>
    </ion-modal>
    <ion-modal
      @didDismiss="grantSuccessModel = false"
      :is-open="grantSuccessModel"
      css-class="tcc-modal"
      :animated="false"
    >
      <div class="model-box">
        <div class="ion-page">
          <div class="modal-content friend-modal">
            <p class="font-18 font-b main-color">
              Claimed! You have {{ stampCardDetail.grantStampQty }} free stamps.
            </p>
            <div class="btn-box">
              <ion-button class="btn dark-btn" @click="getUserStampCard">OK</ion-button>
            </div>
          </div>
        </div>
      </div>
    </ion-modal>
    <ion-content :fullscreen="true">
      <topbar
        :class="{
          'gradient30 fixed': !$store.state.isPC,
          'top-bar-white': $store.state.isPC,
        }"
        :bigtitle="false"
        :showBack="true"
        :showClose="false"
        :share="true"
        :shareData="shareData"
      ></topbar>

      <div class="pc-box margin-top-0 pc-box2">
        <div class="pc-min-content-pct">
          <div class="pc-page-title" v-if="$store.state.isPC">Stamps Card</div>

          <div
            class="content stampCardDetail"
            :style="$store.state.isPC ? 'padding: 40px 0' : ''"
          >
            <div class="collectorCard-img" v-if="!$store.state.isPC">
              <img v-lazy="stampCardDetail.coverImg" :key="stampCardDetail.coverImg" />
            </div>
            <van-tabs
              v-model="activeName"
              style="height: 60px"
              :ellipsis="false"
              @click-tab="tabsEvent"
            >
              <van-tab title="Stamps" name="Stamps">
                <div
                  class="section"
                  :class="{ 'pc-content-flex section-pc': $store.state.isPC }"
                >
                  <div class="collectorCard-img" v-if="$store.state.isPC">
                    <img
                      v-lazy="stampCardDetail.coverImg"
                      :key="stampCardDetail.coverImg"
                    />
                  </div>

                  <div class="collectorCard-info" style="border-radius: 0; margin-top: 0">
                    <div class="collectorCard-type">
                      <img src="@/assets/stampCardIcon.png" />
                      <span>Stamps Card</span>
                    </div>
                    <div class="collectorCard-name main-color font-24 Bold pc-font-28">
                      <span>{{ stampCardDetail.name }}</span>
                    </div>

                    <div v-if="$store.state.isPC">
                      <div class="hr"></div>
                      <div class="period">
                        <label class="Bold pc-font-28">Redemption Period</label>
                        <span class="font-16">
                          {{
                            formatDate(
                              stampCardDetail.redeemStartTime,
                              $store.state.form.merchantDetail.dateFormat
                            )
                          }}
                          -
                          {{
                            formatDate(
                              stampCardDetail.redeemEndTime,
                              $store.state.form.merchantDetail.dateFormat
                            )
                          }}
                        </span>
                      </div>
                      <div class="period">
                        <label class="Bold pc-font-28">Promotion Runs</label>
                        <span class="font-16">
                          {{
                            formatDate(
                              stampCardDetail.collStartTime,
                              $store.state.form.merchantDetail.dateFormat
                            )
                          }}
                          -
                          {{
                            formatDate(
                              stampCardDetail.collEndTime,
                              $store.state.form.merchantDetail.dateFormat
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="solid-hr" style="width: 100%; height: 1.5px"></div>

                <div
                  class="section"
                  :class="{ 'section-pc': $store.state.isPC }"
                  style="padding-top: 10px; background-color: #fff"
                >
                  <ion-card class="stamp-card">
                    <ion-card-header class="card-header">
                      <ion-card-title
                        class="stampCardTitle Bold"
                        :class="{ 'main-color': $store.state.isPC }"
                      >
                        <span class="pc-font-24">You have earned：</span>
                        <!-- <p style="font-size: 14px; color: #a6a6a6">Only stamps earned from purchase are transferrable!</p> -->
                        <div class="stamp-circle">
                          <div class="stamp-num font-16">
                            {{ checkStampNum(stampsCardInfo.collectStampQty) }}
                          </div>
                        </div>
                      </ion-card-title>
                    </ion-card-header>

                    <div class="card-content">
                      <div class="detail-contain">
                        <div
                          class="detail-contain-item"
                          v-for="(stamp, index) in stamps"
                          :key="index"
                        >
                          <div
                            class="detail-contain-itemIn stampsModule"
                            v-show="!stamp.stampImg"
                            ref="numItem"
                          >
                            {{ stamp.num }}
                          </div>
                          <div
                            class="detail-contain-itemIt stampsModule"
                            v-show="stamp.stampImg"
                            ref="numItem"
                          >
                            <img
                              class="detail-contain-itemImg"
                              v-lazy="stamp.stampImg"
                              :key="stamp.stampImg"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="hr"></div>
                      <div class="btn-group">
                        <ion-button
                          color="primary"
                          style="margin-bottom: 20px"
                          v-if="canCollect && $store.state.appVersion"
                          @click="collectEvent"
                          >Collect</ion-button
                        >
                        <ion-button
                          class="transfer-btn"
                          v-if="canTransfer && $store.state.appVersion"
                          @click="transferCollectorCard"
                          >Transfer</ion-button
                        >
                      </div>
                    </div>
                    <div class="circles" v-if="!$store.state.isPC"></div>
                    <div
                      class="circles"
                      style="left: auto; right: -11px"
                      v-if="!$store.state.isPC"
                    ></div>
                  </ion-card>
                </div>
              </van-tab>

              <van-tab title="Rewards" name="Rewards">
                <div style="background: #f2f2f2">
                  <div
                    class="section"
                    :class="{ 'pc-content-flex section-pc': $store.state.isPC }"
                  >
                    <div class="collectorCard-img" v-if="$store.state.isPC">
                      <img
                        v-lazy="stampCardDetail.coverImg"
                        :key="stampCardDetail.coverImg"
                      />
                    </div>

                    <div
                      class="collectorCard-info"
                      style="border-radius: 0; margin-top: 0"
                    >
                      <div class="collectorCard-type">
                        <img src="@/assets/stampCardIcon.png" />
                        <span>Stamps Card</span>
                      </div>
                      <div class="collectorCard-name main-color font-24 Bold pc-font-28">
                        <span>{{ stampCardDetail.name }}</span>
                      </div>
                      <div class="hr"></div>
                      <div class="period">
                        <label class="Bold pc-font-28">Redemption Period</label>
                        <span class="font-16">
                          {{
                            formatDate(
                              stampCardDetail.redeemStartTime,
                              $store.state.form.merchantDetail.dateFormat
                            )
                          }}
                          -
                          {{
                            formatDate(
                              stampCardDetail.redeemEndTime,
                              $store.state.form.merchantDetail.dateFormat
                            )
                          }}
                        </span>
                      </div>
                      <div class="period">
                        <label class="Bold pc-font-28">Promotion Runs</label>
                        <span class="font-16">
                          {{
                            formatDate(
                              stampCardDetail.collStartTime,
                              $store.state.form.merchantDetail.dateFormat
                            )
                          }}
                          -
                          {{
                            formatDate(
                              stampCardDetail.collEndTime,
                              $store.state.form.merchantDetail.dateFormat
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="solid-hr" style="width: 100%; height: 1.5px"></div>
                  <div class="section" :class="{ 'section-pc': $store.state.isPC }">
                    <div class="offer-details">
                      <div class="title Bold pc-font-28">Promo details</div>
                      <showMore
                        :content="stampCardDetail.descr"
                        :showHeight="!$store.state.isPC ? 70 : 68"
                      ></showMore>
                      <!-- <div class="content font-16" ref="offerDetails" style="height: auto; margin-bottom: 0; padding: 0 20px 30px" v-if="!$store.state.isPC">
                        {{ stampCardDetail.descr }}
                      </div> -->
                    </div>
                    <div class="tandC" :class="{ 'pc-tandC': $store.state.isPC }">
                      <div class="title Bold pc-font-28">Terms & Conditions</div>
                      <showMore
                        :content="stampCardDetail.terms"
                        :showHeight="!$store.state.isPC ? 70 : 68"
                      ></showMore>
                    </div>

                    <div class="solid-hr" style="width: 100%; height: 1.5px"></div>

                    <div class="rewards">
                      <div class="title Bold pc-font-28">Rewards</div>
                      <div class="rewards-box" style="height: auto">
                        <div
                          class="stampReward"
                          v-for="(reward, index) in rewardList"
                          :key="index"
                        >
                          <img
                            v-lazy="reward.image"
                            :key="reward.image"
                            @click="goRewardDetail(reward)"
                          />
                          <div class="reward-name Bold" @click="goRewardDetail(reward)">
                            {{ reward.name }}
                          </div>
                          <i
                            class="icon-like red likeReward"
                            v-if="reward.isLike"
                            @click="likeEvent(reward)"
                          ></i>
                          <i
                            class="icon-unlike likeReward"
                            v-else
                            @click="likeEvent(reward)"
                          ></i>
                          <div
                            class="reward-tag pc-font-16"
                            @click="goRewardDetail(reward)"
                          >
                            {{ reward.exchangeTypeList[0].stampQty }} stamps
                            <span v-if="reward.exchangeTypeList[0].cashQty">+ $</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </van-tab>
            </van-tabs>
          </div>
          <div class="height50"></div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonCardTitle,
  IonCard,
  IonCardHeader,
  IonButton,
  IonModal,
} from "@ionic/vue";
import config from "../config";
import topbar from "@/components/topbar.vue";
import showMore from "@/components/showMore.vue";

import $ from "jquery";

export default {
  name: "stampCardDetail",
  mixins: [config.globalMixin],
  data() {
    return {
      stampCardDetail: {},
      rewardList: [],
      activeName: "Stamps",
      stampsCardInfo: {},
      isPopupGrant: true,
      stampNum: [],
      stamps: [],
      canCollect: false,
      canTransfer: false,
      stampsArray: {},
      shareData: {},
      preGrantModel: false,
      grantSuccessModel: false,
      stampHeight: "",
    };
  },
  components: {
    IonContent,
    IonPage,
    topbar,
    IonCardTitle,
    IonCard,
    IonCardHeader,
    IonButton,
    IonModal,
    showMore,
  },
  methods: {
    async init() {
      const objdata = this.getAuthObj();
      objdata.campaignId = this.$route.params.id;
      objdata.apiUrl = this.apiConfig.getCampaignDetail;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.stampCardDetail = data.data.result;
      this.stampCardDetail.collStartTime = this.stampCardDetail.collStartTime.replaceAll(
        "-",
        "/"
      );
      this.stampCardDetail.collEndTime = this.stampCardDetail.collEndTime.replaceAll(
        "-",
        "/"
      );
      this.stampCardDetail.redeemStartTime = this.stampCardDetail.redeemStartTime.replaceAll(
        "-",
        "/"
      );
      this.stampCardDetail.redeemEndTime = this.stampCardDetail.redeemEndTime.replaceAll(
        "-",
        "/"
      );
      const timeArr = [
        "collStartTime",
        "collEndTime",
        "redeemStartTime",
        "redeemEndTime",
        "startTime",
        "endTime",
        "currDate",
      ];
      for (let key in this.stampCardDetail) {
        if (timeArr.indexOf(key) >= 0) {
          this.stampCardDetail[key] = this.stampCardDetail[key].replaceAll("-", "/");
        }
      }
      this.stampCardDetail.totalNum = 1;
      this.collStartTime = this.getTimeStamp(this.stampCardDetail.collStartTime);
      this.collEndTime = this.getTimeStamp(this.stampCardDetail.collEndTime);
      this.redeemStartTime = this.getTimeStamp(this.stampCardDetail.redeemStartTime);
      this.redeemEndTime = this.getTimeStamp(this.stampCardDetail.redeemEndTime);
      this.startTime = this.getTimeStamp(this.stampCardDetail.startTime);
      this.endTime = this.getTimeStamp(this.stampCardDetail.endTime);
      this.currDate = this.getTimeStamp(this.stampCardDetail.currDate);
      if (this.startTime < this.currDate && this.currDate < this.endTime) {
        this.canTransfer = true;
      } else {
        this.canTransfer = false;
      }
      if (this.collStartTime <= this.currDate && this.currDate <= this.collEndTime) {
        this.canCollect = true;
      } else {
        this.canCollect = false;
      }
      this.getUserStampCard();
      this.getRewardList();
      this.shareData = {
        title: `${this.$store.state.appName} - Download and Enjoy Exclusive Offers`,
        description: `${this.stampCardDetail.name}
        Promotion Runs: ${this.formatDate(
          this.stampCardDetail.collStartTime,
          this.$store.state.form.merchantDetail.dateFormat
        )} - ${this.formatDate(
          this.stampCardDetail.collEndTime,
          this.$store.state.form.merchantDetail.dateFormat
        )}`,
        image: this.stampCardDetail.coverImg,
      };
    },
    async getUserStampCard() {
      this.grantSuccessModel = false;
      const objdata = this.getAuthObj();
      objdata.stampCardId = this.stampCardDetail.stampCardId;
      objdata.campaignId = this.$route.params.id;
      objdata.apiUrl = this.apiConfig.getUserStampCard;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.stampsCardInfo = data.data.result;
      this.setStamp(this.stampsCardInfo.collectStampQty);
      this.getListUserStamp();

      if (
        this.stampCardDetail.grantStampQty == null ||
        this.stampCardDetail.grantStampQty <= 0 ||
        !this.canCollect ||
        !this.isPopupGrant
      ) {
        return;
      } else if (
        this.stampsCardInfo.isGrant != 0 ||
        this.stampsCardInfo.isPopGrant != 0
      ) {
        return;
      } else if (
        this.stampCardDetail.grantType == 1 &&
        this.$store.state.form.userInfo.loyaltyState == 1
      ) {
        return;
      }
      this.isPopupGrant = false;
      this.preGrantEvent();
      setTimeout(() => {
        this.stampNumHeight();
      }, 200);
    },
    preGrantEvent() {
      this.preGrantModel = true;
    },
    async grantEvent() {
      this.preGrantModel = false;
      const objdata = this.getAuthObj();
      objdata.userStampCardId = this.stampsCardInfo.id;
      objdata.campaignId = this.$route.params.id;
      objdata.apiUrl = this.apiConfig.grantUserStamp;
      const data = await this.$store.dispatch("apiEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.grantSuccessModel = true;
      this.sendGAEvent("Click", "StampCardDetail", "Accept");
    },
    async getListUserStamp() {
      const objdata = this.getAuthObj();
      objdata.userStampCardId = this.stampsCardInfo.id;
      objdata.apiUrl = this.apiConfig.listUserStamp;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.userStamps = data.data.result;
      for (let i = 0; i < this.userStamps.length; i++) {
        if (this.stampsArray[i]) {
          this.stampsArray[i].stampImg = this.userStamps[i].stampImg;
        }
      }
      this.stamps = this.stampsArray;
    },
    stampNumHeight: function () {
      let height;
      if (this.$store.state.isPC) {
        height = (window.innerWidth * 0.8 - 320) / 10;
      } else {
        height = (window.innerWidth - 130) / 5;
      }
      this.stampHeight = height;
      // $(".stampsModule").css({
      //   "line-height": height + "px",
      //   height: height + "px",
      // });
    },
    setStamp(num) {
      const compare = num % 5;
      this.stampNum = "";
      if (num <= 100) {
        this.stampNum = 100 + num - compare;
        this.stampNum = parseInt(this.stampNum);
        this.startNum = 1;
      } else {
        this.stampNum = num + 100 - compare;
        this.stampNum = parseInt(this.stampNum);
        this.startNum = this.stampNum - 200 + 1;
      }

      this.stampsArray = {};
      for (let i = this.startNum; i <= this.stampNum; i++) {
        this.stampsArray[i - 1] = {};
        this.stampsArray[i - 1].num = i;
      }

      if (!this.stampHeight) {
        const height = (window.innerWidth - window.innerWidth * 0.08 - 50) / 5;
        this.stampHeight = height;
      }
      if (num > 10) {
        const setTop = setInterval(() => {
          if ($(".detail-contain").scrollTop() > 0) {
            clearInterval(setTop);
          }
          $(".detail-contain").scrollTop(
            (this.stampHeight + 10.3) * Math.floor((num - this.startNum) / 5 - 1)
          );
        }, 300);
      }
    },
    async getRewardList() {
      const objdata = this.getAuthObj();
      objdata.campaignId = this.$route.params.id;
      objdata.apiUrl = this.apiConfig.listGift;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.rewardList = [];
      data.data.result.forEach((item) => {
        if (item.exchangeTypeList.length) {
          this.rewardList.push(item);
        }
      });
      // this.rewardList.sort(function (a, b) {
      //   if (a.exchangeTypeList[0] && b.exchangeTypeList[0]) {
      //     return (
      //       a.exchangeTypeList[0].stampQty - b.exchangeTypeList[0].stampQty
      //     );
      //   }
      //   return 1;
      // });
    },
    async likeEvent(reward) {
      const objdata = this.getAuthObj();
      objdata.campaignGiftMapId = reward.id;
      objdata.type = reward.isLike ? 0 : 1;
      objdata.apiUrl = this.apiConfig.likeGift;
      const data = await this.$store.dispatch("apiFormEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      if (!reward.isLike) {
        this.toastEvent("Liked this reward");
      }
      reward.isLike = !reward.isLike;
    },
    backEvent() {
      this.$router.push({
        path: this.$store.state.form.backPage
          ? this.$store.state.form.backPage
          : "/main/landing",
      });
    },
    collectEvent() {
      this.sendGAEvent("Click", "StampCardDetail", "Collect");
      this.$router.push({
        path: "/wallet",
      });
    },
    transferCollectorCard() {
      this.sendGAEvent("Click", "StampCardDetail", "Transfer");
      this.$router.push({
        path: "/selectFriend",
        query: {
          stamp: this.stampsCardInfo.collectStampQty,
          stampCardId: this.stampCardDetail.stampCardId,
          mode: "stamp",
        },
      });
    },
    showMore(type) {
      this.$refs[type].style.height = "auto";
    },
    tabsEvent(name) {
      this.activeName = name;
    },
    goRewardDetail(item) {
      this.$router.push({
        path: "/rewardDetail/" + item.giftId + "/" + this.$route.params.id,
        query: {
          stamps: this.stampsCardInfo.collectStampQty,
          stampCardId: this.stampsCardInfo.id,
          redemption:
            this.formatDate(
              this.stampCardDetail.redeemStartTime,
              this.$store.state.form.merchantDetail.dateFormat
            ) +
            "-" +
            this.formatDate(
              this.stampCardDetail.redeemEndTime,
              this.$store.state.form.merchantDetail.dateFormat
            ),
        },
      });
    },
  },
  ionViewWillEnter() {
    this.isPopupGrant = true;
    this.init();
    this.$store.state.exitApp = false;
    this.sendGAPageView("Stamps Card");
  },
  ionViewDidEnter() {
    setTimeout(() => {
      this.$store.state.getNotification = null;
    }, 2000);
  }
};
</script>
<style scoped></style>
